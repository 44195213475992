import React from "react";
import terreiro from "../components/images/terreiro.jpeg";
import { calendar } from "../text/calendar";
import logo from "../components/images/logo.png";

const getDay = () => {
  let allDays = [];
  calendar.forEach((i, idx) => {
    i.items.forEach((j) => {
      allDays.push({
        date: `${j.data.slice(0, 2)}/${idx}`,
        desc: j.desc,
      });
    });
  });
  let found = false;
  let day = 0;
  let month = 0;
  let response;

  allDays.forEach((i) => {
    if (found) {
      return;
    }
    const currentDate = new Date();
    const targetDate = new Date(
      2024,
      +i.date.slice(3, 5),
      +i.date.slice(0, 2) + 1
    );
    if (currentDate <= targetDate) {
      found = true;
      response = i.desc;
      day = i.date.slice(0, 2);
      month = +i.date.slice(3, 5) + 1;
      if (month.toString().length === 1) {
        month = `0${month}`;
      }
    }
  });

  return (
    <>
      Dia {day}/{month} <br />
      {response}
    </>
  );
};

const Home = () => {
  return (
    <div className="content">
      <div className="hero-1">
        <img className="hero-cover" alt="terreiro-banner" src={terreiro} />
        <div className="home-header">
          <img alt="logo" src={logo} />
          <div>
            <h1>Isto é Umbanda.</h1>
            <p>Mestre Marne, mestre de Umbanda e Cultos Afros.</p>
          </div>
        </div>
      </div>
      <div className="page-content">
        <br />
        <h2>Próximo evento</h2>
        {getDay()}
        <h2>2025 - Ano de Oxossi e Yansã</h2>
        <h3>Previsões</h3>
        <p>
          Em virtude do ano começar numa quarta-feira, que pela Tábua
          Cronológica De Cambinda, que de acordo com nossos estudos é a que mais
          perto chega da perfeição, o ano será regido por OXÓSSI com a força de
          IANSÃ. Mesmo sendo Iansã em segundo plano, a regência maior será de
          OXÓSSI – CAÇADOR DE ALMAS, SENHOR DA FLORESTA, REI DAS MATAS, DONO DA
          TERAPÊUTICA DO ESPAÇO (SAÚDE). Os filhos de OXÓSSI são reconhecidos
          pela soma de apenas dois números: 26 e 28 (consulte a tábua
          numerológica no site mestremarne.com). Características do filho de
          OXÓSSI. No campo da amizade, é o melhor amigo que você pode ter. É
          capaz de tirar a própria camisa para ajudar um amigo. São
          desconfiados, honestos com seus amigos, cuidadosos em tudo,
          preocupados com dia de amanhã. Bom amigo, calmos e explosivos. Quando
          calmos chegam a irritar e quando explosivos vão às raias da
          ignorância. Os filhos de Oxóssi trazem um estigma preocupante, pois
          parece que nasceram para serem eternamente solteiros em virtude de
          passarem uma vida inteira em busca da mulher ideal que só existe na
          cabeça deles. Por isso ao casar com qualquer mulher tem dificuldades
          imensas de fazer elas felizes em virtude deste estigma de procurar a
          mulher ideal. A única mulher que ainda aceita o jeito estranho de amar
          dos filhos de Oxossi, são as filhas de Iansã, que mesmo assim, chega
          um momento na vida de casada que se aborrecem tornando o
          relacionamento como “cansada” e até insuportável.
          <br />
          <br />
          DEFEITOS: São um tanto “fantasiosos” com os problemas. Um dos defeitos
          é a “acomodação” ou seja, não tem princípios de ganância, mesmo não
          sendo defeito em alguns momentos da vida. Dificilmente são confiáveis
          com suas parceiras. Estão sujeitos a “paixões” relâmpagos a primeira
          vista. Geralmente casam muitas vezes para não ficarem na solidão.
          Ligeiramente preguiçosos, muitas vezes aumenta ou inventa coisa, nada
          que prejudiquem outrem. Tem imensa dificuldade de usar as palavras
          “meu amor”, “minha querida”, enfim, dialogar com a companheira, mesmo
          amando-a. Porém, quando perde a pessoa amada, entra em parafuso, ou
          seja, só valoriza quando perde a pessoa amada. <br />
          <br />
          ACONTECIMENTOS MARCANTES DO ANO: Será mais uma vez o avanço da
          medicina na busca da cura do Alzheimer e da Demência. Continuam os
          estudos para minimizar os danos da quimioterapia. O avanço do
          espiritualismo. A Reencarnação ganhará mais adeptos. O respeito ao
          público LGBT crescerá. As atividades que ganharão mais valorização
          ainda: relacionadas com ervas, plantas, madeiras e suplementos
          alimentares. Pesca, Veterinária, Jornalismo, Marketing, Turismo,
          Ecologia e Representação Comercial. <br />
          <br /> ACONTECIMENTOS NEGATIVOS: Doenças: Estômago, regiões das
          pernas, joelhos e pés, Rins. Com a continuação do aquecimento global e
          calor extremo, se tornará perigoso: Vendavais, ciclones, tufões,
          terremotos e secas, passageiras, mas que poderão causar alguns danos.
          Em virtude das queimadas, será um ano difícil para a Agricultura, pois
          a natureza não perdoa. A falta de água será preocupante. O ser humano
          vai aprender no amor ou na dor a respeitar a natureza, pois Oxóssi
          (mata) e Iansã (vento) exigirão respeito neste sentido. <br /> <br />
          OXÓSSI: Dia da Semana: Quarta Feira. Cor Verde.
          <br /> IANSÃ: Dia da Semana: Quarta-Feira. Cor Azulão.
        </p>
        <h3>Ponto cantado - Bará Lodê 2024</h3>
        <div className="two-columns">
          <iframe
            title="mestre-marne-video"
            className="video"
            width="420"
            height="315"
            src="https://www.youtube.com/embed/5sxWTGmnu38"
          ></iframe>
          <p>
            ELE É BARÁ LODÊ yubára, yubára
            <br />
            O ELÊG VAMOS SAUDAR yubárayubára
            <br />
            SENHOR NA LUA E NO SOL yubára, yubára
            <br />
            SENHOR DA RUA É BARÁ yubára yubára. <br /> Bis todo o verso
            <br />
            <br />
            VAMOS SAUDAR O ELEGBÁ
            <br />
            ELEGBÁRA É BARÁ
            <br />
            O SEU PODER É ODÓLONÃ
            <br />
            LODÊ É LALUPONÃ
            <br />
            yubára, yubára. <br /> Bis todo o segundo verso.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
